<script setup lang="ts">
import { FlightDeal } from '#types/flight';
import { ref, computed, onMounted, onBeforeUnmount, useFlightRecommendation } from '#imports';

export interface IFlightRecommendationSlideProps {
  recommendations: FlightDeal[];
}

const { cityImage, showFlightResult, trackFlightPopular } = useFlightRecommendation();

const activeRecommendationIndex = ref<number>(0);
const isPaused = ref<boolean>(true);
const autoplay = ref<NodeJS.Timer>();

const props = defineProps<IFlightRecommendationSlideProps>();

const activeRecommendation = computed(() => props.recommendations[activeRecommendationIndex.value]!);

const nextSlide = () => {
  if (activeRecommendationIndex.value < props.recommendations.length - 1) {
    activeRecommendationIndex.value++;
  } else {
    activeRecommendationIndex.value = 0;
  }
};
const stopAutoplay = () => {
  isPaused.value = true;

  clearInterval(autoplay.value);
};

const startAutoplay = () => {
  if (props.recommendations.length > 0 && isPaused.value) {
    autoplay.value = setInterval(nextSlide, 5000);
  }
};

onMounted(() => {
  startAutoplay();
});

onBeforeUnmount(() => {
  clearInterval(autoplay.value);
});
</script>

<template>
  <NuxtLink
    :to="showFlightResult(activeRecommendation)"
    class="flight-recommendation-slides rounded"
    rel="nofollow"
    external
    @mouseover="stopAutoplay"
    @mouseleave="startAutoplay"
    @click="trackFlightPopular(activeRecommendation)"
  >
    <Transition
      name="slide-prev"
      mode="out-in"
    >
      <NuxtImg
        :alt="activeRecommendation.arrAirportCity"
        :src="cityImage(activeRecommendation.arrAirportCity)"
        format="webp"
        class="city-image rounded h-full w-full object-cover absolute inset-0 transition-all duration-200"
      />
    </Transition>

    <div class="overlay text-white absolute inset-0 p-20">
      <Transition
        name="slide-prev"
        mode="out-in"
        :duration="250"
      >
        <div
          :key="activeRecommendationIndex"
          class="w-full h-full relative z-[1]"
          data-testid="recommended-card"
        >
          <div class="flight-desc flex nowrap relative">
            <p class="title whitespace-nowrap">
              {{ $t('deals.flightto') }}
            </p>

            <p class="title-alt whitespace-nowrap absolute translate-x-[100px]">
              {{ $t('deals.flightfrom') }} {{ activeRecommendation.depAirportCity }}
            </p>
          </div>

          <div class="flight-destination text-medium font-bold mt-5">
            <ApzIcon
              icon="plane"
              class="plane-icon mr-10"
            />
            <span>{{ activeRecommendation.arrAirportCity }}</span>
          </div>

          <div class="divider mt-20 bg-white transition-all w-0 h-[0.5px]" />

          <div class="flight-price absolute bottom-0 flex items-end right-0 gap-x-5 font-bold">
            {{ $t('search.frompromo') }}

            <div class="text-medium mt-5 transition-all duration-300">
              {{ $c(activeRecommendation.price, activeRecommendation.currency) }}
            </div>
          </div>

          <span class="arrow-icon absolute top-auto right-20 bottom-20 left-auto">
            <ApzIcon
              :icon="['fal', 'arrow-right']"
              size="2x"
            />
          </span>
        </div>
      </Transition>
    </div>
  </NuxtLink>
</template>

<style scoped lang="scss">
.slide-prev-enter-active,
.slide-prev-leave-active {
  transition: transform 250ms ease-out;
}

.slide-prev-leave-to {
  transform: translate3d(-100%, 0, 0);
  position: absolute;
  width: 100%;
}

.slide-prev-enter-from {
  transform: translate3d(100%, 0, 0);
  position: absolute;
  width: 100%;
}

.overlay {
  &::after {
    @apply absolute top-0 left-0 right-0 h-[48%] -rotate-180;

    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 4.25%, rgba(0, 0, 0, 0.4) 71.67%);
  }

  &::before {
    @apply absolute bottom-0 left-0 right-0 h-[33%];

    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 4.25%, rgba(0, 0, 0, 0.4) 71.67%);
  }
}

.divider {
  height: 0.5px;
  transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
  transition-duration: 0.6s;
}

.flight-desc {
  .title,
  .title-alt {
    @apply transition-transform;

    transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
    transition-duration: 0.6s;
  }

  .title-alt {
    @apply invisible;
  }
}

.plane-icon,
.arrow-icon {
  @apply invisible transition-transform;

  transform: translateX(-20px);
  transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
  transition-duration: 0.6s;
}

.flight-destination {
  @apply transition-transform;

  transform: translateX(-30.25px);
  transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
  transition-duration: 0.6s;
}

.flight-price {
  @apply transition-transform;

  transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
  transition-duration: 0.6s;
}

.flight-recommendation-slides {
  @apply relative overflow-hidden;

  height: 180px;

  &:hover {
    .city-image {
      @apply blur-sm scale-110;
    }

    .overlay {
      @apply bg-[#00000080];
    }

    .divider {
      @apply w-full;
    }

    .flight-desc {
      .title {
        @apply -translate-x-[100px] invisible;
      }

      .title-alt {
        @apply -translate-x-0 visible;
      }
    }

    .plane-icon,
    .arrow-icon {
      @apply visible translate-x-0;
    }

    .flight-destination {
      @apply translate-x-0;
    }

    .flight-price {
      @apply left-0 flex-col items-start;

      > div {
        @apply text-extra;
      }
    }
  }
}
</style>
